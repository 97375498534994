import { DateTime } from "luxon";

export function truncateMiddle(value: string, length: number = 16): string {
  if (!value) return value;
  if (value.length <= length) return value;
  const mid = Math.floor(length / 2);
  return `${value.slice(0, mid)}...${value.slice(-mid)}`;
}

export function toSentenceCase(text: string): string {
  if (!text) return text;
  return text.charAt(0).toLocaleUpperCase() + text.slice(1).toLocaleLowerCase();
}

export function toTitleCase(text: string): string {
  if (!text) return text;
  const articles = new Set([
    "a",
    "an",
    "the",
    "and",
    "but",
    "or",
    "for",
    "nor",
    "on",
    "at",
    "to",
    "with",
  ]);
  return text
    .toLocaleLowerCase()
    .split(/\s+/)
    .map((word, index) =>
      articles.has(word) && index !== 0
        ? word
        : word.charAt(0).toLocaleUpperCase() + word.slice(1),
    )
    .join(" ");
}

export function formatPhoneNumber(phone: string): string {
  if (!phone) return phone;
  return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
}

export function toDateString(date: string, showTime: boolean = false): string {
  if (!date) return date;

  // Normalize the date string to ensure uppercase "T" and "Z"
  const normalizedDate = date.replace("t", "T").replace("z", "Z");

  const dt = DateTime.fromISO(normalizedDate); // Parse the ISO date string

  if (!dt.isValid) {
    return "Invalid Date";
  }

  return showTime
    ? dt.toLocaleString(DateTime.DATETIME_SHORT) // Includes both date and time
    : dt.toLocaleString(DateTime.DATE_SHORT); // Date only
}

export function toRelativeTime(date: string | null): string {
  if (!date) return "Invalid Date";
  const dt = DateTime.fromISO(date);
  return dt.isValid ? dt.toRelative() : "Invalid Date";
}

export const toPercentage = (value: number | undefined) =>
  value === undefined ? "N/A" : `${Math.round(value)}%`;

export const toKwh = (value: number) =>
  value === undefined ? null : `${Math.round(value / 1000)} kWh`;

export const toKw = (value: number) =>
  value === undefined ? null : `${Math.round(value / 1000)} kW`;

export const toAmps = (value: number) =>
  value === undefined ? null : `${Math.round(value)} A`;

export const toVolts = (value: number) =>
  value === undefined ? null : `${Math.round(value)} V`;

export const toWatts = (value: number) =>
  value === undefined ? null : `${Math.round(value)} W`;

export const toMiles = (value: number) =>
  value === undefined ? null : `${Math.round(value)} mi`;

export const toBoolean = (value: boolean | undefined): string | null =>
  value === undefined ? null : value ? "Yes" : "No";

interface ChartDataItem {
  [key: string]: any;
}

interface TransformedDataItem {
  xValue: Date;
  yValue: any;
  categoryValue?: any;
}

export const prepareChartData = ({
  data,
  xKey,
  yKey,
  categoryKey,
}: {
  data: ChartDataItem[];
  xKey: string;
  yKey: string;
  categoryKey?: string;
}): TransformedDataItem[] => {
  return data.map((item, index) => {
    if (!(xKey in item)) {
      throw new Error(`Missing xKey "${xKey}" in data item at index ${index}`);
    }
    if (!(yKey in item)) {
      throw new Error(`Missing yKey "${yKey}" in data item at index ${index}`);
    }

    const dateValue = new Date(item[xKey]);
    if (isNaN(dateValue.getTime())) {
      throw new Error(
        `Invalid date value for xKey "${xKey}" at index ${index}`,
      );
    }

    const transformedItem: TransformedDataItem = {
      xValue: new Date(item[xKey]),
      yValue: item[yKey],
    };

    if (categoryKey && item[categoryKey] !== undefined) {
      transformedItem.categoryValue = item[categoryKey];
    }

    return transformedItem;
  });
};
