import React from "react";
import { Icon } from "@/edges/ui/Icon";
import { Tooltip } from "@/edges/ui/Tooltip";
import { Button } from "@/edges/ui/Button";
import { FieldErrorProps, Group, GroupProps, InputProps as RACInputProps, LabelProps as RACLabelProps, FieldError as RACFieldError, Input as RACInput, Label as RACLabel, Text, TextProps, composeRenderProps } from "react-aria-components";
import { twMerge } from "tailwind-merge";
import { composeTailwindRenderProps, focusRing } from "@/edges/utils";
export interface LabelProps extends RACLabelProps {
  className?: string;
  tooltip?: string;
  isRequired?: boolean;
  size?: "sm" | "md" | "lg" | "xl";
}
export function Label(props: LabelProps) {
  const {
    tooltip,
    isRequired,
    children,
    size = "md",
    ...restProps
  } = props;
  const textSizeClass = {
    sm: "text-controlElementSm",
    md: "text-controlElementMd",
    lg: "text-controlElementLg",
    xl: "text-controlElementXl"
  }[size];
  return <div className="flex items-center gap-1" data-sentry-component="Label" data-sentry-source-file="Field.tsx">
      <RACLabel {...restProps} className={twMerge("w-fit cursor-default font-bold leading-[2.25]", textSizeClass, "!text-text-caption", props.className)} data-sentry-element="RACLabel" data-sentry-source-file="Field.tsx">
        {children}
        {isRequired && <span className="ml-1 text-lg font-medium text-text-caption">*</span>}
      </RACLabel>
      {tooltip && <Tooltip content={tooltip}>
          <Button variant="icon" className="pl-1 pr-0">
            <Icon name="Info" size={18} />
          </Button>
        </Tooltip>}
    </div>;
}
interface DescriptionProps extends TextProps {
  size?: "sm" | "md" | "lg" | "xl";
}
export function Description(props: DescriptionProps) {
  const {
    size = "md",
    ...restProps
  } = props;
  const textSizeClass = {
    sm: "text-controlElementSm",
    md: "text-controlElementMd",
    lg: "text-controlElementLg",
    xl: "text-controlElementXl"
  }[size];
  return <Text {...restProps} slot="description" className={twMerge("leading-[2.25] text-text-muted", textSizeClass, props.className)} data-sentry-element="Text" data-sentry-component="Description" data-sentry-source-file="Field.tsx" />;
}
interface CustomFieldErrorProps {
  className?: string;
  size?: "sm" | "md" | "lg" | "xl";
}
export function FieldError(props: CustomFieldErrorProps & React.ComponentProps<typeof RACFieldError>) {
  const {
    size = "md",
    ...restProps
  } = props;
  const textSizeClass = {
    sm: "text-controlElementSm",
    md: "text-controlElementMd",
    lg: "text-controlElementLg",
    xl: "text-controlElementXl"
  }[size];
  return <RACFieldError {...restProps} className={twMerge("leading-[32px] text-feedback-error-text", textSizeClass, props.className)} data-sentry-element="RACFieldError" data-sentry-component="FieldError" data-sentry-source-file="Field.tsx" />;
}
export interface InputProps extends RACInputProps {
  textSize?: "sm" | "md" | "lg" | "xl";
  transparent?: boolean;
}
export function Input(props: InputProps) {
  const {
    textSize = "md",
    transparent = false,
    ...restProps
  } = props;
  const textSizeClass = {
    sm: "text-controlElementSm h-[theme(controlElementSize.sm)]",
    md: "text-controlElementMd h-[theme(controlElementSize.md)]",
    lg: "text-controlElementLg h-[theme(controlElementSize.lg)]",
    xl: "text-controlElementXl h-[theme(controlElementSize.xl)]"
  }[textSize];
  return <RACInput {...restProps} className={composeTailwindRenderProps(props.className, twMerge("text-text-action-primary disabled:bg-background-disabled min-w-0 flex-1 px-2 py-0 leading-[24px] placeholder:text-text-placeholder", !transparent && "bg-background-input", transparent && "bg-transparent", textSizeClass))} data-sentry-element="RACInput" data-sentry-component="Input" data-sentry-source-file="Field.tsx" />;
}
export function getFieldBorderStyles({
  isFocusWithin = false,
  isInvalid = false,
  isDisabled = false
} = {}) {
  const styles = [isFocusWithin ? "border-border-focus dark:border-zinc-300 forced-colors:border-[Highlight]" : "border-border-input dark:border-zinc-500 forced-colors:border-[ButtonBorder]", isInvalid && "border-feedback-error-border dark:border-feedback-error-border forced-colors:border-[Mark]", isDisabled && "border-gray-200 dark:border-zinc-700 forced-colors:border-[GrayText]"].filter(Boolean);
  return styles.join(" ");
}
export function getFieldGroupStyles(props: {
  isFocusWithin?: boolean;
  isInvalid?: boolean;
  isDisabled?: boolean;
  className?: string;
  transparent?: boolean;
}) {
  return twMerge(focusRing(), "group flex items-center h-9 forced-colors:bg-[Field] border-2 rounded-lg overflow-hidden", !props.transparent && "bg-background-input", props.transparent && "bg-transparent", getFieldBorderStyles(props), props.className);
}
export function FieldGroup(props: GroupProps & {
  transparent?: boolean;
}) {
  return <Group {...props} className={composeRenderProps(props.className, (className, renderProps) => getFieldGroupStyles({
    ...renderProps,
    className,
    transparent: props.transparent
  }))} data-sentry-element="Group" data-sentry-component="FieldGroup" data-sentry-source-file="Field.tsx" />;
}