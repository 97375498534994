import React from "react";
import { composeRenderProps, Button as RACButton, ButtonProps as RACButtonProps } from "react-aria-components";
import { Icon } from "@/edges/ui/Icon";
import { focusRing } from "@/edges/utils";
export interface ButtonProps extends RACButtonProps {
  variant?: "primary" | "secondary" | "destructive" | "icon" | "link" | "unstyled" | "ghost";
  size?: "sm" | "md" | "lg" | "xl";
  badgeNumber?: number;
  fullWidth?: boolean;
  icon?: string;
  iconPosition?: "left" | "right";
  isLoading?: boolean;
}
const baseStyles = "px-5 flex font-medium justify-center items-center gap-3 text-center transition rounded-default";
const variantStyles = {
  primary: "bg-action-primary text-white hover:bg-action-primary-hover forced-colors:bg-[ButtonText] forced-colors:text-[ButtonFace]",
  secondary: "bg-transparent text-text-body border-2 border-border-muted hover:bg-background-hover forced-colors:border-[ButtonBorder] forced-colors:bg-[ButtonFace] forced-colors:text-[ButtonText]",
  ghost: "border-none text-text-body hover:bg-background-hover forced-colors:text-[ButtonText] forced-colors:hover:bg-[ButtonFace]",
  destructive: "border text-action-destructive hover:bg-background-hover forced-colors:bg-[Mark] forced-colors:text-[ButtonFace]",
  link: "!text-action-primary hover:text-action-primary-hover hover:underline hover:decoration-2 hover:underline-offset-4",
  icon: "border-none text-text-body hover:bg-background-hover forced-colors:text-[ButtonText] forced-colors:hover:bg-[ButtonFace] p-2 flex-shrink-0",
  unstyled: "bg-transparent"
};
const sizeStyles = {
  sm: "h-[theme(controlElementSize.sm)] text-controlElementSm",
  md: "h-[theme(controlElementSize.md)] text-controlElementMd",
  lg: "h-[theme(controlElementSize.lg)] text-controlElementLg",
  xl: "h-[theme(controlElementSize.xl)] text-controlElementXl"
};
const widthStyles = {
  full: "w-full",
  default: "w-fit"
};
const disabledStyles = "opacity-60 cursor-not-allowed";
const enabledStyles = "opacity-100 cursor-pointer";
function getButtonStyles(props: ButtonProps) {
  const {
    variant = "primary",
    size = "md",
    isDisabled,
    fullWidth = false,
    isLoading = false
  } = props;
  let styles;
  if (variant === "unstyled") {
    styles = "";
  } else {
    styles = `${baseStyles} ${variantStyles[variant]} ${sizeStyles[size]}`;
  }
  if (isDisabled || isLoading) {
    styles += ` ${disabledStyles}`;
  } else {
    styles += ` ${enabledStyles}`;
  }
  if (fullWidth) {
    styles += ` ${widthStyles.full}`;
  } else {
    styles += ` ${widthStyles.default}`;
  }
  return `${styles} ${focusRing()}`;
}
export function Button(props: ButtonProps) {
  const {
    icon,
    children,
    isLoading = false,
    size,
    iconPosition = "left"
  } = props;
  let iconSize = 20;
  if (size === "sm") {
    iconSize = 14;
  } else if (size === "lg") {
    iconSize = 28;
  } else if (size === "xl") {
    iconSize = 32;
  }
  const renderChildren = () => {
    const iconElement = icon ? <Icon name={icon} size={iconSize} /> : null;
    const content = <>
        {iconPosition === "left" && iconElement}
        {typeof children === "function" ? children({
        isHovered: false,
        isPressed: false,
        isFocused: false,
        isFocusVisible: false,
        isDisabled: !!props.isDisabled,
        isPending: false,
        defaultChildren: null
      }) : children}
        {iconPosition === "right" && iconElement}
      </>;
    if (isLoading) {
      return <div className="relative inline-flex items-center justify-center">
          <div className="absolute inset-0 flex items-center justify-center">
            <Icon name="CircleNotch" className="animate-spin" size={iconSize} />
          </div>
          <div className="invisible">{content}</div>
        </div>;
    }
    return content;
  };
  const buttonComponent = <RACButton {...props} className={composeRenderProps(props.className, className => `${getButtonStyles(props)} ${className || ""}`)}>
      {renderChildren()}
    </RACButton>;
  if (props.badgeNumber && props.badgeNumber > 0) {
    return <div className="relative">
        {buttonComponent}
        <span className="bg-text absolute left-0 top-0 -ml-2 -mt-1 flex h-4 w-4 items-center justify-center rounded-full text-xs text-white">
          {props.badgeNumber}
        </span>
      </div>;
  } else {
    return buttonComponent;
  }
}